<div class="flex-col card">
  <div class="flex-row flex-space-between align-flex-center" style="margin-bottom: 16px">
    <span class="text-big">
      <b>{{ 'ConsignmentDetails.Content' | translate | uppercase }}</b>
    </span>
  </div>

  <div class="flex-col gap-4">
    <div class="flex-row weight-row"><span>{{ data().loadName }}</span> <span>{{ data().weight | round }} kg</span>
    </div>
    <span>{{ data().amount }} {{ data().posArt ?? ('ConsignmentDetails.NoPosArt' | translate) }}</span>
  </div>
</div>
