import {ChangeDetectionStrategy, Component} from '@angular/core';
import {map, Observable} from 'rxjs';
import {BrandingService} from '@app-services';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrl: './contact-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardComponent {
  protected contactHref = this.brandingService
    .getContactMail()
    .pipe(map((mail) => `mailto:${mail}`));

  constructor(private readonly brandingService: BrandingService) {
  }

  protected getContactViewBackground(): Observable<{ [key: string]: string }> {
    return this.brandingService.getContactBackgroundUrl().pipe(
      map((url) => {
        return {'background-image': `url(${url})`};
      })
    );
  }
}
