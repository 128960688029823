import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ConsignmentDetails} from '@app-models';

@Component({
  selector: 'app-consignment-timing-details',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './consignment-timing-details.component.html',
  styleUrl: './consignment-timing-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentTimingDetailsComponent {
  public data = input.required<ConsignmentDetails>();
}
