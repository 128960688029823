import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {ConsignmentDetails} from '@app-models';

@Component({
  selector: 'app-consignment-references',
  templateUrl: './consignment-references.component.html',
  styleUrl: './consignment-references.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentReferencesComponent {
  public data = input.required<ConsignmentDetails>();
}
