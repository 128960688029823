import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {BrandingService, ConfigInitService} from '@app-services';
import {KeycloakAngularModule} from 'keycloak-angular';
import {errorInterceptor, loaderInterceptor, RequestCancelInterceptor,} from '@app-interceptors';
import {HeaderComponent} from '@app-shared-components';
import {ICustomerPortalEnvironment} from '@app-environments/ICustomerPortalEnvironment';
import {DataService, WebApiDataService} from './core/services/data-access';
import {BaseDataService, ENVIRONMENT} from '@drip/core';
import {environment} from '@app-environments/environment';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

function brandingInitializer(brandingService: BrandingService) {
  return () => brandingService.init();
}

export function DataServiceFactory(http: HttpClient, env: ICustomerPortalEnvironment) {
  // if (env.stage !== EnvironmentStage.PRODUCTION) {
  //   return new FakeApiDataService();
  // }
  return new WebApiDataService(http, env);
}

export function BaseDataServiceFactory(dataService: DataService) {
  return dataService;
}

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    BrowserModule,

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
  ],
  providers: [
    ConfigInitService,
    {provide: ENVIRONMENT, useValue: environment},
    {
      provide: DataService,
      useFactory: DataServiceFactory,
      deps: [HttpClient, ENVIRONMENT],
    },
    {
      provide: BaseDataService,
      useFactory: BaseDataServiceFactory,
      deps: [DataService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: brandingInitializer,
      multi: true,
      deps: [BrandingService],
    },
    provideHttpClient(withInterceptors([loaderInterceptor, errorInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCancelInterceptor,
      multi: true,
    },
    {provide: MAT_DATE_LOCALE, useValue: 'de-AT'},
    provideMomentDateAdapter({
      parse: {
        dateInput: 'DD.MM.YYYY',
      },
      display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD',
        monthYearA11yLabel: 'MM',
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
