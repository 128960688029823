import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {PATHS} from '@app-enums';
import {ConsignmentDetails} from '@app-models';
import {BrandingService, DataService, HeaderService} from '@app-services';

@Component({
  selector: 'app-consignment-details',
  templateUrl: './consignment-details.component.html',
  styleUrl: './consignment-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentDetailsComponent implements OnInit {
  protected readonly data$ = new BehaviorSubject<ConsignmentDetails | undefined>(undefined);
  protected readonly isPermLink$ = new BehaviorSubject<boolean | undefined>(undefined);
  private path: string;

  constructor(
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly brandingService: BrandingService,
  ) {
    this.headerService.setShowHeader(true);
    this.checkForPermLink();
  }

  //#region lifecycle hooks

  public ngOnInit(): void {
    this.getData();
  }

  //#endregion

  //#region init methods

  /**
   * Get data depending on if url is perm link
   *
   * @returns void
   */
  private getData(): void {
    if (this.isPermLink$.value) {
      this.dataService.consignments
        .getConsignmentsDetailsFromPermLink(this.path)
        .subscribe({
          next: (data) => {
            this.data$.next(data);
          },
          error: () => {
            this.router.navigate([this.brandingService.tenantId, PATHS.LINK_EXPIRED]);
          },
        });
    } else {
      this.dataService.consignments
        .getConsignmentDetails(this.path)
        .subscribe((data) => {
          this.data$.next(data);
        });
    }
  }

  //#endregion

  /**
   * Check if url is permLink
   * if so the path should be string after the permLinkSeparator
   * else path should be ConsignmentId
   *
   * @returns void
   */
  private checkForPermLink(): void {
    this.path = this.activatedRoute.snapshot.params['path'] as string;
    if (this.activatedRoute.snapshot.url.some(part => part.path === 'pl')) {
      this.isPermLink$.next(true);
    } else {
      this.isPermLink$.next(false);
    }
  }

  //#region UI responses

  /**
   * Navigate back to Overview Page
   *
   * @returns void
   */
  protected onGoToPreviousPage(): void {
    this.router.navigateByUrl(
      this.activatedRoute.snapshot.params['companyName']
    );
  }

  //#endregion
}
