import {ChangeDetectionStrategy, Component, input, OnChanges, SimpleChanges,} from '@angular/core';
import {ConsignmentDocument} from '@app-models';

@Component({
  selector: 'app-consignment-documents',
  templateUrl: './consignment-documents.component.html',
  styleUrl: './consignment-documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentDocumentsComponent implements OnChanges {
  public documents = input<ConsignmentDocument[]>([]);
  protected documentFilter: string[];
  protected selectedFilter = 'all';

  protected redirectTo(url: string): void {
    window.open(url, '_blank');
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['documents']) {
      this.documentFilter = ['all'];
      this.documents().forEach((doc) => {
        if (!this.documentFilter.includes(doc.type)) {
          this.documentFilter.push(doc.type);
        }
      });
    }
  }
}
