<div class="flex-col card">
  <div
    class="flex-row flex-space-between align-flex-center"
    style="margin-bottom: 16px"
  >
    <span class="text-big">
      <b>{{ 'ConsignmentDetails.DeliveryStatus' | translate | uppercase }}</b>
    </span>
  </div>

  <div class="delivery-date-wrapper">
    <div class="small-gray-label">
      {{ 'Overview.PlannedDeliveryDate' | translate | uppercase }}
    </div>
    <span style="font-weight: bold">
      {{
        (data().receiver?.plannedDate | date : 'dd.MM.YYYY HH:mm') ||
        '-'
      }}
    </span>
  </div>
  <div class="delivery-date-wrapper">
    <div class="small-gray-label">
      {{ 'Overview.DeliveryDate' | translate | uppercase }}
    </div>
    <span class="delivery-date {{data().statusTime}}">
      {{
        (data().receiver?.actualDate | date : 'dd.MM.YYYY HH:mm') || '-'
      }}
    </span>
  </div>
  <div class="black-div pos-top-right">
    {{ 'Overview.' + data().statusTime | translate }}
  </div>
</div>
