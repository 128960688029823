import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'round',
  standalone: true,
})
export class RoundPipe implements PipeTransform {
  public transform(value: unknown): unknown {
    const numbVal = Number(value);
    if (Number.isNaN(numbVal)) {
      return value;
    }
    return Math.ceil(numbVal);
  }
}
