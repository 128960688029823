<div class="card">
  <div class="text-big document-title">
    <b>{{ 'Documents.Label' | translate | uppercase }}</b>
  </div>
  @if (documents().length) {
    <div class="document-filter-row">
      @for (filter of documentFilter; track filter) {
        <div class="document-filter"
             (click)="selectedFilter = filter"
             [ngClass]="{ 'document-filter--active': filter === selectedFilter }"
        >
          {{ filter === 'all' ? ('Documents.FilterAll' | translate) : filter }}
        </div>
      }
    </div>
  }

  <div class="document-content">
    @if (documents()) {
      @if (documents().length) {
        @for (d of documents(); track d.filename) {
          <div
            class="doc-row clickable-row"
            (click)="redirectTo(d.url)"
            [ngClass]="{
            'doc-row--hide':
              selectedFilter !== 'all' && selectedFilter !== d.type
          }"
          >
            <div class="doc-data">
              <i class="fas fa-file file-icon"></i>
              <div class="doc-description">
                <div class="title">
                  {{ d.type }}
                </div>
                <div class="doc-ownership">
                  {{ d.filename }}
                </div>
              </div>
            </div>
            <div class="download-icon">
              <i class="fas fa-download"></i>
            </div>
          </div>
        }
      } @else {
        {{ 'Documents.NoDocs' | translate }}
      }
    } @else {
      <div class="doc-row">
        <mat-spinner diameter="25"></mat-spinner>
      </div>
    }
  </div>
</div>
