<div class="page-wrapper">
  @if ((isPermLink$ | async) === false) {
    <div class="header">
    <span (click)="onGoToPreviousPage()" class="cursor-pointer previous-page">
      {{ 'Overview.Title' | translate }} </span>&nbsp;>&nbsp;
      <span class="gray-text">
      {{ 'ConsignmentDetails.TrackingNumber' | translate }}
    </span>
      <span>&nbsp;{{ (data$ | async)?.consignmentExternalId }}</span>
    </div>
  }

  <app-loader></app-loader>

  <div class="page-content">
    <div class="left-col">
      @if (data$ | async; as data) {
        <div class="grid-layout">

          <div class="content-row">
            <app-consignment-detail-status
              [status]="data.status"
              [statusTime]="data.statusTime"
            ></app-consignment-detail-status>
            <app-consignment-references [data]="data"></app-consignment-references>
            <app-consignment-timing-details [data]="data"></app-consignment-timing-details>
          </div>

          <div class="content-row">
            <app-address-card label="ConsignmentDetails.LoadingAddress"
                              [addressData]="data.sender?.address"></app-address-card>
            <app-address-card label="ConsignmentDetails.Recipient"
                              [addressData]="data.receiver?.address"></app-address-card>

            <app-consignment-detail [data]="data"></app-consignment-detail>
          </div>

          <div class="content-row">
            <app-consignment-history [data]="data"></app-consignment-history>
          </div>

          <div class="content-row">
            <app-consignment-documents
              [documents]="data.documents"
            ></app-consignment-documents>
          </div>
        </div>
      }
    </div>
    <div class="right-col contact-col">
      <app-contact-card></app-contact-card>
    </div>
  </div>
</div>
