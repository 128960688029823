import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PATHS} from '@app-enums';
import {OverviewComponent} from './modules/overview/pages/overview/overview.component';
import {AuthGuard} from '@app-guards';
import {ConsignmentDetailsComponent} from './modules/overview/pages/consignment-details/consignment-details.component';
import {ErrorPageComponent} from '@app-shared-components';

const routes: Routes = [
  {
    path: PATHS.AUTH,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'pl/:path',
    component: ConsignmentDetailsComponent,
  },
  {
    path: '',
    component: OverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':companyName',
    component: OverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':companyName/pl/:path',
    component: ConsignmentDetailsComponent,
  },
  {
    path: `:companyName/${PATHS.LINK_EXPIRED}`,
    component: ErrorPageComponent,
  },
  {
    path: ':companyName/auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: ':companyName/:path',
    component: ConsignmentDetailsComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
