import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {finalize} from 'rxjs';
import {LoaderService} from '@app-services';

/**
 * Interceptor for handling loading indicators during HTTP requests.
 * This interceptor shows a loader before the request is sent and hides it after the request is completed.
 *
 * @param req The HttpRequest to intercept.
 * @param next The handler function for the next interceptor or the HTTP request.
 *
 * @returns HttpInterceptorFn
 */
export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderService = inject(LoaderService);
  if (req.headers.get('singletonLoader') === 'true') {
    return next(req).pipe();
  } else {
    loaderService.showLoader();
  }
  return next(req).pipe(
    finalize(() => {
      loaderService.hideLoader();
    })
  );
};
