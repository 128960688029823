<div class="flex-col card">
  <div
    class="flex-row flex-space-between align-flex-center"
    style="margin-bottom: 16px"
  >
    <span class="text-big">
      <b>{{ label() | translate | uppercase }}</b>
    </span>
  </div>

  <div class="flex-col gap-4">
    <span><b>{{ addressData()?.name ?? ('Overview.NoAddress' | translate) }}</b></span>
    <span>{{ addressData() | address }}</span>
  </div>
</div>
