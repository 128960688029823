import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit,} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {BehaviorSubject, debounceTime, Subject, takeUntil} from 'rxjs';
import {Consignment, ConsignmentList, ConsignmentStatus, DateRangePickerModel,} from '@app-models';
import moment from 'moment';
import {StorageKeys} from '@app-enums';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BrandingService, DataService, HeaderService} from '@app-services';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly rowHeight = 52;
  protected displayedColumns: string[] = [
    'consignmentStatus',
    'reference',
    'sender_address',
    'receiver_address',
    'shipment-id',
    'details',
    'weight',
    'plannedUnloadingDate',
    'date',
  ];
  protected dataSource: MatTableDataSource<Consignment> =
    new MatTableDataSource<Consignment>();

  protected readonly filterFormGroup = new FormGroup({
    dateRange: new FormControl<DateRangePickerModel>(
      { from: null, to: null },
      Validators.required
    ),
    filter: new FormControl<string>(''),
    filterAddress: new FormControl<string>(''),
    status: new FormControl<ConsignmentStatus[]>([])
  });

  protected readonly possibleStatusFilters: ConsignmentStatus[] = [ConsignmentStatus.SCHEDULED, ConsignmentStatus.IN_TRANSIT, ConsignmentStatus.DELIVERED];

  private readonly divObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1, // Adjust this based on how much of the div needs to be in view to trigger the event
  };

  protected totalCount = 0;

  private sort: string = '';
  private limit: number;
  private offset = 0;

  protected readonly isLoading$ = new BehaviorSubject<boolean>(false);
  protected readonly exportLoading$ = new BehaviorSubject<boolean>(false);
  private readonly unsubscribe$ = new Subject<boolean>();

  constructor(
    private readonly dataService: DataService,
    private readonly router: Router,
    private readonly toastCtrl: MatSnackBar,
    private readonly headerService: HeaderService,
    private readonly brandingService: BrandingService,
  ) {
    this.setDefaultDatesAndSearch();
    this.headerService.setShowHeader(true);
  }

  //#region Lifecycle hooks

  public ngOnInit(): void {
    this.subscriptionToFilterChanges();
  }

  public ngAfterViewInit() {
    const tableWrapperDiv = document.getElementById('table-wrapper');
    if (tableWrapperDiv) {
      this.limit =
        Math.floor(tableWrapperDiv.clientHeight / this.rowHeight) + 2;
    } else {
      this.limit = 25;
    }
    this.getData();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  //#endregion

  //#region  UI responses

  /**
   * Clear search input.
   *
   * @returns void
   */
  protected onClearSearchInput(): void {
    this.filterFormGroup.controls.filter.setValue('');
  }

  protected onClearAddressInput(): void {
    this.filterFormGroup.controls.filterAddress.setValue('');
  }

  protected freshLoad() {
    this.dataSource.data = [];
    this.offset = 0;
    this.getData();
  }

  protected exportFile() {
    if (!this.filterFormGroup.valid) {
      this.toastCtrl.open('Date range is invalid', 'OK', { duration: 1500 });
    }
    const from = this.filterFormGroup.controls.dateRange.value?.from;
    const to = this.filterFormGroup.controls.dateRange.value?.to;
    const search = this.filterFormGroup.controls.filter.value?.trim() ?? '';
    const searchAddress = this.filterFormGroup.controls.filterAddress.value?.trim() ?? '';
    const statusFilter: ConsignmentStatus[] = this.filterFormGroup.controls.status.value ?? [];
    if (from && to) {
      this.exportLoading$.next(true);
      this.dataService.consignments
        .getExport(
          Math.floor(from.toDate().getTime() / 1000),
          Math.floor(to.toDate().getTime() / 1000),
          search,
          searchAddress,
          statusFilter,
          this.sort
        )
        .subscribe({
          next: (file) => {
            const aElement = document.createElement('a');
            const fromFormat = from.format('YYYYMMDD');
            const toFormat = to.format('YYYYMMDD');
            aElement.setAttribute(
              'download',
              `export_${fromFormat}_${toFormat}`
            );
            const href = URL.createObjectURL(file);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
          },
          complete: () => {
            this.exportLoading$.next(false);
          },
        });
    }
  }

  /**
   * On sort , get data again
   *
   * @param event Sort
   *
   * @returns void
   */
  protected onSortChange(event: Sort): void {
    if (event.direction === '') {
      this.sort = '';
    } else {
      this.sort = event.active + ':' + event.direction;
    }
    this.dataSource.data = [];
    this.offset = 0;

    this.getData();
  }

  protected async openDetailView(element: Consignment) {
    this.saveCurrentFilter();
    this.router.navigate([`/${this.brandingService.tenantId}/${element.consignmentId}`]);
  }

  //#endregion

  //#region Subscriptions

  /**
   * Subscription to search field input.
   *
   * @returns void
   */
  private subscriptionToFilterChanges(): void {
    this.filterFormGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(250))
      .subscribe(() => {
        if (this.filterFormGroup.valid) {
          this.freshLoad();
        }
      });
  }

  //#endregion

  //#region methods

  /**
   * Get data depending on pagination state
   *
   * @returns void
   */
  private getData(): void {
    if (this.offset !== 0) {
      this.isLoading$.next(true);
    }
    const from = this.filterFormGroup.controls.dateRange.value?.from;
    const to = this.filterFormGroup.controls.dateRange.value?.to;
    const search = this.filterFormGroup.controls.filter.value?.trim() ?? '';
    const searchAddress = this.filterFormGroup.controls.filterAddress.value?.trim() ?? '';
    const statusFilter: ConsignmentStatus[] = this.filterFormGroup.controls.status.value ?? [];
    this.saveCurrentFilter();
    if (from && to) {
      this.dataService.consignments
        .getConsignments(
          Math.floor(from.toDate().getTime() / 1000),
          Math.floor(to.toDate().getTime() / 1000),
          this.limit,
          this.offset,
          search,
          searchAddress,
          statusFilter,
          this.sort
        )
        .subscribe({
          next: (consignmentList) => {
            this.dataSource.data = [
              ...this.dataSource.data,
              ...consignmentList.consignments,
            ];
            this.totalCount = consignmentList.totalCount;
            this.setUpScrollTrigger(consignmentList);
          },
          complete: () => {
            this.isLoading$.next(false);
          },
        });
    }
  }

  /**
   * When second to last item get into view, load more if possible
   *
   * @param consignmentList ConsignmentList
   *
   * @returns void
   */
  private setUpScrollTrigger(consignmentList: ConsignmentList): void {
    this.offset = this.limit + this.offset;
    if (
      this.offset < this.totalCount &&
      consignmentList.consignments.length > 0
    ) {
      const rows = document.getElementsByClassName('single-row');
      const divObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target); // Stop observing the specific element
            observer.disconnect(); // Disconnect the observer entirely
            this.getData();
          }
        });
      }, this.divObserverOptions);

      // Observe the desired element
      divObserver.observe(rows.item(rows.length - 2)!);
    }
  }

  /**
   * Save current filter(search and dates)
   *
   * @returns void
   */
  private saveCurrentFilter(): void {
    localStorage.setItem(
      StorageKeys.CURRENT_FILTER,
      JSON.stringify({
        search: this.filterFormGroup.controls.filter.value,
        addressSearch: this.filterFormGroup.controls.filterAddress.value,
        start: this.filterFormGroup.controls.dateRange.value?.from,
        end: this.filterFormGroup.controls.dateRange.value?.to,
        status: this.filterFormGroup.controls.status.value,
      })
    );
  }

  /**
   * Get previous filter if saved
   *
   * @returns any
   */
  private getPreviousFilter(): {
    search: string;
    addressSearch: string;
    status: ConsignmentStatus[];
    start: string;
    end: string;
  } | null {
    let currentFilter;
    currentFilter = localStorage.getItem(StorageKeys.CURRENT_FILTER);
    localStorage.removeItem(StorageKeys.CURRENT_FILTER);
    if (currentFilter) {
      currentFilter = JSON.parse(currentFilter);
      return currentFilter;
    } else {
      return null;
    }
  }

  /**
   * Set default date range
   * one month ago - today
   *
   * @returns void
   */
  private setDefaultDatesAndSearch(): void {
    let startDate = new Date(); // now
    let endDate = new Date(startDate); // in one month

    startDate.setDate(startDate.getDate() - 14);
    endDate.setDate(endDate.getDate() + 14);
    const currentFilter = this.getPreviousFilter();

    this.filterFormGroup.controls.filter.setValue(currentFilter?.search ?? '');
    this.filterFormGroup.controls.filterAddress.setValue(currentFilter?.addressSearch ?? '');
    this.filterFormGroup.controls.status.setValue(currentFilter?.status ?? []);

    if (currentFilter?.start && currentFilter?.end) {
      startDate = new Date(currentFilter.start);
      endDate = new Date(currentFilter.end);
    }

    // Assign the data to the data source for the table to render
    const dateRange: DateRangePickerModel = {
      from: moment.unix(startDate.getTime() / 1000),
      to: moment.unix(endDate.getTime() / 1000),
    };
    this.filterFormGroup.controls.dateRange.setValue(dateRange);
  }

  //#endregion
}
