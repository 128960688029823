import {Pipe, PipeTransform} from '@angular/core';
import {ShipmentAddress} from '@app-models';

@Pipe({
  name: 'address',
  standalone: true,
})
export class AddressPipe implements PipeTransform {
  public transform(value: ShipmentAddress | undefined): string {
    if (!value) {
      return 'Overview.NoAddress';
    }
    return `${value.country}-${value.zip} ${value.city}, ${value.street} ${value.houseNumber ?? ''}`;
  }
}
