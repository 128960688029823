import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {catchError} from 'rxjs';

/**
 * Error interceptor to handle HTTP errors globally.
 *
 * @param req The HttpRequest to intercept.
 * @param next The HttpHandler for the next interceptor or the HTTP request.
 *
 * @returns HttpInterceptorFn.
 */
export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      throw `${error.status}: ${error.message}`;
    })
  );
};
