import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShipmentAddress} from '@app-models';
import {TranslateModule} from '@ngx-translate/core';
import {AddressPipe} from '@app-pipes';

@Component({
  selector: 'app-address-card',
  standalone: true,
  imports: [CommonModule, TranslateModule, AddressPipe],
  templateUrl: './address-card.component.html',
  styleUrl: './address-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressCardComponent {
  public label = input.required<string>();
  public addressData = input<ShipmentAddress>();
}
