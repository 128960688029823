import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsignmentDetails} from '@app-models';
import {TranslateModule} from '@ngx-translate/core';
import {RoundPipe} from '@app-pipes';

@Component({
  selector: 'app-consignment-detail',
  standalone: true,
  imports: [CommonModule, TranslateModule, RoundPipe],
  templateUrl: './consignment-detail.component.html',
  styleUrl: './consignment-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentDetailComponent {
  public data = input.required<ConsignmentDetails>();
}
