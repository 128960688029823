<div class="flex-row big-icon-row">
  <div class="big-icon" [class.success]="status() === 'delivered'">
    <i *ngIf="status() === 'scheduled'" class="fas fa-warehouse-alt"></i>
    <i *ngIf="status() === 'loaded'" class="fas fa-truck-loading"></i>
    <i *ngIf="status() === 'in_transit'" class="fas fa-shipping-fast"></i>
    <i *ngIf="status() === 'delivered'" class="fas fa-box-check"></i>
  </div>
  <div class="flex-col">
    <span class="status-maintext">
      {{ 'Status.' + status() | translate }}
    </span>
    <span
      [ngClass]="{
      'green-text': statusTime() === StatusTime.IN_TIME,
      'red-text': statusTime() === StatusTime.TOO_LATE
    }"
      class="status-subtext gray-text"
    >
    {{ 'Overview.' + statusTime() | translate }}
  </span>
  </div>
</div>
