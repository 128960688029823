export interface ConsignmentListCompat {
  totalCount: number;
  consignments: Consignment[] | ConsignmentCompat[];
}

export interface ConsignmentList extends ConsignmentListCompat {
  consignments: Consignment[];
}

export interface ShipmentAddress {
  name: string;
  country: string;
  zip: string;
  city: string;
  street: string;
  houseNumber?: string;
}

/**
 * For backwards compatibility with backend
 */
export interface LocationInformationCompat {
  unloadingAddress: ShipmentAddress;
  plannedUnloadingDate?: string;
  unloadingDate?: string;
}

export interface LocationInformation {
  address: ShipmentAddress;
  plannedDate?: string;
  actualDate?: string;
}

export interface ConsignmentCompat {
  consignmentId: string;
  consignmentExternalId: string;
  shipmentExternalId?: string;
  customerReferenceText: string;
  load?: ConsignmentLoad;
  statusTime: StatusTime | string;
  consignmentStatus: ConsignmentStatus | string;
  receiver?: LocationInformation | LocationInformationCompat;
  sender?: LocationInformation;
}

export interface Consignment extends ConsignmentCompat {
  receiver?: LocationInformation;
}

export enum StatusTime {
  IN_TIME = 'in_time',
  TOO_LATE = 'too_late',
}

export enum ConsignmentStatus {
  SCHEDULED = 'scheduled',
  LOADED = 'loaded',
  IN_TRANSIT = 'in_transit',
  DELIVERED = 'delivered',
}

export interface ConsignmentLoad {
  amount?: number;
  name?: string;
  posArt?: string;
  weight_kg?: number;
}
