<div class="page-wrapper">
  <form [formGroup]="filterFormGroup" class="filter-wrapper">
    <app-date-range-picker formControlName="dateRange"></app-date-range-picker>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>{{ 'Overview.Filter' | translate }}</mat-label>
      <input matInput formControlName="filter" />
      @if (filterFormGroup.controls.filter.value !== "") {
        <mat-icon class="cursor-pointer" (click)="onClearSearchInput()" matSuffix
        >close
        </mat-icon>
      } @else {
        <mat-icon matSuffix>search</mat-icon>
      }
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>{{ 'Overview.FilterAddress' | translate }}</mat-label>
      <input matInput formControlName="filterAddress"/>
      @if (filterFormGroup.controls.filterAddress.value !== "") {
        <mat-icon class="cursor-pointer" (click)="onClearAddressInput()" matSuffix
        >close
        </mat-icon>
      } @else {
        <mat-icon matSuffix>search</mat-icon>
      }
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>{{ 'Overview.FilterStatus' | translate }}</mat-label>
      <mat-select formControlName="status" multiple>
        @for (status of possibleStatusFilters; track status) {
          <mat-option [value]="status">{{ 'Status.' + status | translate }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="total-label">
      <span>{{ 'Overview.FilterCount' | translate:{count: totalCount} }}</span>
    </div>
    <div class="button-row-wrapper">
      <button
        mat-raised-button
        class="btn primary"
        (click)="freshLoad()"
        [disabled]="exportLoading$ | async"
      >
        <div class="button-label">
          <i class="fas fa-sync-alt medium-size-icon"></i>
          <span>Refresh</span>
        </div>
      </button>
      <button mat-raised-button class="btn primary" (click)="exportFile()">
        @if (exportLoading$ | async) {
          <mat-spinner class="export-loading" color="accent"></mat-spinner>
        } @else {
          <div class="button-label">
            <i class="fas fa-download medium-size-icon"></i>
            <span>Export</span>
          </div>
        }
      </button>
    </div>
  </form>
  <div id="table-wrapper" class="full-w full-h position-relative">
    <app-loader></app-loader>
    <table
      (matSortChange)="onSortChange($event)"
      [dataSource]="dataSource"
      mat-table
      matSort
    >
      <!-- Status Column -->
      <ng-container matColumnDef="consignmentStatus">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <!-- class="cursor-pointer" (click)="onSortChange('consignmentStatus')"> -->
          {{ 'Overview.Status' | translate | uppercase }}
          <!-- MULTI SORT HEADER #
          <span *ngIf="(customSort$ | async)!['consignmentStatus'];let direction">
            <i *ngIf="direction==='asc'" class="fas fa-arrow-alt-up"></i>
            <i *ngIf="direction==='desc'" class="fas fa-arrow-alt-down"></i>
          </span> -->
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex-row align-flex-center gap-20">
            <i
              [ngClass]="{
                'fa-warehouse-alt': row.consignmentStatus === 'scheduled',
                'fa-truck-loading': row.consignmentStatus === 'loaded',
                'fa-shipping-fast': row.consignmentStatus === 'in_transit',
                'fa-box-check': row.consignmentStatus === 'delivered'
              }"
              class="fas medium-size-icon"
            ></i>
            <span>{{ 'Status.' + row.consignmentStatus | translate }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Reference Column -->
      <ng-container matColumnDef="reference">
        <th *matHeaderCellDef mat-header-cell>
          <div class="reference-container">
            <span>
              {{ 'ConsignmentDetails.CustomerReference' | translate | uppercase }}
            </span>
            <span>
              {{ 'ConsignmentDetails.TrackingNumber' | translate | uppercase }}
            </span>
          </div>
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="reference-container">
              <span><b>{{ row.customerReferenceText }}</b></span>
            <span>{{ row.consignmentExternalId }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="sender_address">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'Overview.LoadingAddress' | translate | uppercase }}
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex-col">
            <span><b>{{ row.sender?.address?.name }}</b></span>
            <span>{{ row.sender?.address | address | translate }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Address Column -->
      <ng-container matColumnDef="receiver_address">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'Overview.DeliveryAddress' | translate | uppercase }}
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex-col">
            <span><b>{{ row.receiver?.address?.name }}</b></span>
            <span>{{ row.receiver?.address | address | translate }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Shipment ID Column -->
      <ng-container matColumnDef="shipment-id">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'ConsignmentDetails.ShipmentId' | translate | uppercase }}
        </th>
        <td *matCellDef="let row" mat-cell>
          <span *ngIf="row.shipmentExternalId">{{ row.shipmentExternalId }}</span>
        </td>
      </ng-container>

      <!-- Details Column -->
      <ng-container matColumnDef="details">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'Overview.Details' | translate | uppercase }}
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex-col">
            <span>{{ row.load.name }}</span>
            <span>{{ row.load.amount }} {{ row.load.posArt }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="weight">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'Overview.Weight' | translate | uppercase }}
        </th>
          <td *matCellDef="let row" mat-cell>{{ row.load.weight_kg | round }} kg</td>
      </ng-container>

      <ng-container matColumnDef="plannedUnloadingDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <!-- class="cursor-pointer" (click)="onSortChange('plannedUnloadingDate')"> -->
          {{ 'Overview.PlannedDeliveryDate' | translate | uppercase }}
          <!-- MULTI SORT HEADER #
          <span *ngIf="(customSort$ | async)!['plannedUnloadingDate'];let direction">
            <i *ngIf="direction==='asc'" class="fas fa-arrow-alt-up"></i>
            <i *ngIf="direction==='desc'" class="fas fa-arrow-alt-down"></i>
          </span> -->
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex-col">
            <span>
              {{ row.receiver.plannedDate | date : 'dd.MM.YYYY' }}
            </span>
            <span>
              {{ row.receiver.plannedDate | date : 'HH:mm' }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'Overview.DeliveryDate' | translate | uppercase }}
        </th>
        <td *matCellDef="let row" mat-cell>
          <div class="flex-col">
            <span [ngClass]="{
              'green-text': row.statusTime === 'in_time',
              'red-text': row.statusTime === 'too_late'
            }">
              {{ row.receiver.actualDate | date : 'dd.MM.YYYY' }}
            </span>
            <span> {{ row.receiver.actualDate | date : 'HH:mm' }} </span>
          </div>
        </td>
      </ng-container>

      <tr
        *matHeaderRowDef="displayedColumns"
        class="header-row"
        mat-header-row
      ></tr>
      <tr
        class="single-row clickable-row"
        *matRowDef="let row; columns: displayedColumns"
        mat-row
        (click)="openDetailView(row)"
      ></tr>

      <ng-container matColumnDef="loader">
        <td
          mat-footer-cell
          *matFooterCellDef
          colspan="9"
          style="padding: 0px; margin: 0px; border: 0px"
        >
          <div style="position: relative; height: 108px">
            <app-loader [loaderSingleton]="true"></app-loader>
          </div>
        </td>
      </ng-container>
      <tr
        mat-footer-row
        *matFooterRowDef="['loader']"
        [ngClass]="{ 'hide-loader': (isLoading$ | async) !== true }"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell center-text no-data" colspan="9">
          {{ 'Overview.NoDataMatching' | translate }}
        </td>
      </tr>
    </table>
  </div>
</div>
