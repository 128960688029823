import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {ConsignmentStatus, StatusTime} from '@app-models';

@Component({
  selector: 'app-consignment-detail-status',
  templateUrl: './consignment-detail-status.component.html',
  styleUrl: './consignment-detail-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentDetailStatusComponent {
  public status = input.required<ConsignmentStatus>();
  public statusTime = input.required<StatusTime>();
  protected readonly StatusTime = StatusTime;
}
