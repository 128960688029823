<div class="flex-col card">
  <div
    class="flex-row flex-space-between align-flex-center"
    style="margin-bottom: 16px"
  >
    <span class="text-big">
      <b>{{ 'ConsignmentDetails.References' | translate | uppercase }}</b>
    </span>
  </div>

  <span class="small-gray-label">
    {{ 'ConsignmentDetails.CustomerReference' | translate | uppercase }}
  </span>
  <span class="text-normal">{{ data().customerReferenceText }}</span>

  <span class="small-gray-label margin-top-small">
    {{ 'ConsignmentDetails.TrackingNumber' | translate | uppercase }}
  </span>
  <span class="text-normal">{{ data().consignmentExternalId }}</span>

  <span class="small-gray-label margin-top-small" *ngIf="data().shipmentExternalId">
    {{ 'ConsignmentDetails.ShipmentId' | translate | uppercase }}
  </span>
  <span class="text-normal" *ngIf="data().shipmentExternalId">{{ data().shipmentExternalId }}</span>
</div>
