import {Component, Inject} from '@angular/core';
import {HeaderService, LanguageService} from '@app-services';
import {ENVIRONMENT} from '@drip/core';
import {ICustomerPortalEnvironment} from '@app-environments/ICustomerPortalEnvironment';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  protected showHeader$ = this.headerService.showHeader$;

  constructor(
    private readonly languageService: LanguageService,
    private readonly headerService: HeaderService,
    private readonly meta: Meta,
    @Inject(ENVIRONMENT) environment: ICustomerPortalEnvironment
  ) {
    this.meta.addTag({
      name: 'version',
      content: environment.version
    });
    this.languageService.init();
  }
}
