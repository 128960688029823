<div
  style="
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: var(--colors-background);
  "
>
  <app-header *ngIf="showHeader$ |async"></app-header>
  <router-outlet></router-outlet>
</div>
