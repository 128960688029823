<div class="flex-col card">
  <div
    class="flex-row flex-space-between align-flex-center"
    style="margin-bottom: 16px"
  >
    <span class="text-big">
      <b>{{ 'ConsignmentDetails.ShipmentHistory' | translate | uppercase }}</b>
    </span>
  </div>

  <div class="flex-col gap-20">
    @for (item of data().statusHistory; track $index) {
      <div class="flex-row gap-20 align-flex-center">
        <div class="flex-col align-flex-center icon-column text-big">
          @if (item.status === 'scheduled') {
            <i class="fas fa-warehouse-alt"></i>
          } @else if (item.status === 'loaded') {
            <i class="fas fa-truck-loading"></i>
          } @else if (item.status === 'in_transit') {
            <i class="fas fa-shipping-fast"></i>
          } @else if (item.status === 'delivered') {
            <i class="fas fa-box-check"></i>
          } @else {
            <i class="fas fa-pallet-alt"></i>
          }
          @if ($index !== data().statusHistory.length - 1) {
            <div class="dots full-dots column-dots"></div>
          }
        </div>
        <div class="flex-col">
        <span>
          {{ 'Status.' + item.status | translate }}
        </span>
        </div>
        <span class="gray-text time-label">
        {{ item.dateTime | date : 'dd.MM.YYYY HH:mm' }}
      </span>
      </div>
    }
  </div>
</div>
